#prodotto{
  #paragrafo{
    background: $lightGrey;
    position: relative;
    padding: 0 100px;
    padding-bottom: 40px;
    @media #{$underdesktop} {
      padding: 0 40px;
      padding-bottom: 100px;
    }
    @media #{$undermd} {
      padding: 0 20px;
      padding-bottom: 100px;
    }
    .categoria{
      font-size: 35px;
      font-family: $font;
      margin-bottom: 20px;
      line-height: 40px;
      @media #{$small-phone} {
        font-size: 25px;
        text-align: center;
      }
    }
    .descrizione{
      position: relative;
      top:50%;
      @include transform(translateY(-50%));
      @media #{$undermd} {
        top:0%;
        @include transform(translateY(0%));
      }

    }
    .immagine{
      position: relative;
      top:50%;
      @include transform(translateY(-50%));
      @media #{$undermd} {
        top:0%;
        @include transform(translateY(0%));
      }
    }

    .link_scheda{
      background: $blue;
      padding: 10px;
      border-radius: 20px;
      color: $white;
      font-size: 18px;
      margin-top: 20px;
      font-family: $font;
      font-weight: 300;
      text-decoration: none;
      position: relative;
      display: inline-block;
      @media #{$undermd} {
        left:50%;
        @include transform(translateX(-50%));
      }
    }
    .link_scheda:hover{
      background: $black;
      padding: 10px;
      border-radius: 20px;
      color: $white;
      font-size: 18px;
      margin-top: 20px;
      font-family: $font;
      font-weight: 300;
      text-decoration: none;
      position: relative;
      display: inline-block;
      @media #{$undermd} {
        left:50%;
        @include transform(translateX(-50%));
      }
    }
  }

  #titolo-info{
    background: $lightGrey;
    text-align: center;

    padding: 0 20px;
    @media #{$underdesktop} {
      padding-right: 30px;
    }
    @media #{$undermd} {
        padding: 0 20px;
    }
  }

  #informazioni{
    background: $lightGrey;
    padding-top: 40px;
    padding-bottom: 40px;
    @media #{$undermd} {
      padding-bottom: 80px;
    }
    @media #{$undertablet} {
      padding-bottom: 400px;
    }

    .boxPlus{
      padding: 0 100px;
      @media #{$underdesktop} {
        padding: 0 60px;
      }
      @media #{$undermd} {
        padding: 0 20px;
      }
      @media #{$underdesktop} {
        padding: 0 30px;
      }

      .plus-box{
        margin-bottom: 60px;
        @media #{$undermd} {
          text-align: center;
        }
        .icona{
          img{
            max-width: 80px;
            @media #{$undermd} {
              margin: 0 auto;
            }
          }
        }

        ul{
          text-align: left;
          display: inline-block;
        }
        .titolo{
          color: $blue;
          h4{
            margin-top: 10px;
            font-size: 25px;
          }
        }
      }
    }
  }

  .cta-prodotti{
    z-index: 2;
    position: relative;
    .icona{
      img{

      }
    }
  }

  #titolo-altri{
    background: $white;
    z-index: 2;
    position: relative;
  }

  #altriProdottiCategoria{
    position: relative;
    z-index: 2;
    background: $white;
    .box-prodotto{
      border:2px solid $lightGrey;
      border-bottom: 6px solid $blue;
      padding: 0;
      a{
        text-decoration: none;
      }

       figure{
         transition: all 0.3s ease;
         -webkit-transition: all 0.3s ease;
         -o-transition: all 0.3s ease;
         -moz-transition: all 0.3s ease;
         background: $white;
       }
       h4{
         font-size: 30px;
         -webkit-transform: translate3d(0,0px,0);
         transform: translate3d(0,0px,0);
         -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
         @media #{$small-phone} {
           font-size: 25px;
         }
       }

       .blockProd{
         .link-category{
           opacity: 0;
           border-radius: 50%;
           padding: 5px 15px;
           font-size: 25px;
           top: 20px;
           display: inline-block;
           position: relative;
           -webkit-transform: translate3d(0,0px,0);
           transform: translate3d(0,0px,0);
           transition: opacity 0.5s, transform 0.5s;
           background: $white;
           color: $white;
         }
       }

     }
    .box-prodotto:hover{
       border:2px solid $lightGrey;
       border-bottom: 6px solid $blue;

       figure{
         background: $blue;
         transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
       }

       h4{
         font-size: 30px;
         color: $white;
         -webkit-transform: translate3d(0,-120px,0);
         transform: translate3d(0,-120px,0);
         -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
         @media #{$small-phone} {
           font-size: 25px;
           color: $white;
           -webkit-transform: translate3d(0,-50px,0);
           transform: translate3d(0,-50px,0);
         }
       }

       .blockProd{
         .link-category{
           opacity: 1;
           color: $blue;
           border-radius: 50%;
           background: $white;
           padding: 5px 15px;
           font-size: 25px;
           top: 20px;
           display: inline-block;
           position: relative;
           transition: opacity 0.5s, transform 0.5s;
           -webkit-transform: translate3d(0,-120px,0);
           transform: translate3d(0,-120px,0);
           -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
           @media #{$small-phone} {
             font-size: 25px;
             -webkit-transform: translate3d(0,-50px,0);
             transform: translate3d(0,-50px,0);
           }
         }
         img{
           opacity: 0;
           -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
         }
       }

     }
  }

  #titolo-altri{
    padding: 40px 0;
    text-align: center;

  }

  .btn-load-more{
    background: transparent !important;
    border: 1px solid $grey;
    color: $grey;
    border-radius: 50px;
    padding: 10px 30px;
    left: 50%;
    @include transform(translateX(-50%));
    position: relative;
    margin-bottom: 100px;

  }

  .nav-prodotto{
    position: fixed;
    width: 60px;
    right: 60px;
    z-index: 1;
    @media #{$undermd} {
      display: none;
    }
    @media #{$small-phone} {
      display: none;
    }

    li{
      display: block;
      width: 100%;
      list-style: none;

      .span-div:before{
        width: 1px;
        background: $grey;
        height: 35px;
        content: "";
        position: absolute;
        right: 13px;
        top: -55px;
      }

      .span-div{
        height: 95px;
        position: relative;

        span{
          top: 20px;
          color: $grey;
        }

        span:before{
          content: "";
          position: absolute;
          top: 0;
          left: 1px;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          top:3px;
          margin-left: -19px;
          margin-top: -19px;
          border: 3px solid $grey;
          background: transparent;
          -webkit-transform: scale(.25);
          -ms-transform: scale(.25);
          transform: scale(.25);
          display: block;
          -webkit-transition: -webkit-transform .3s cubic-bezier(.4,0,0,1);
          transition: -webkit-transform .3s cubic-bezier(.4,0,0,1);
          transition: transform .3s cubic-bezier(.4,0,0,1);
          transition: transform .3s cubic-bezier(.4,0,0,1),-webkit-transform .3s cubic-bezier(.4,0,0,1);
        }
      }

      .span-div:hover{
        span:before{
          -webkit-transform: scale(0.75);
          -ms-transform: scale(0.75);
          transform: scale(0.75);
          border: 3px solid $grey;
        }

      }

      a.active{
        .span-div{
          span:before{
            -webkit-transform: scale(0.75);
            -ms-transform: scale(0.75);
            transform: scale(0.75);
            border: 3px solid $grey;
          }

        }
      }
    }

    li:last-child{
      .span-div:after{
        color: $white;
        width: 1px;
        background: $grey;
        height: 40px;
        content: "";
        position: absolute;
        left: 7px;
        top: 37px;
      }
    }
  }

  #scheda{
    position: relative;
    z-index: 0;
    .box-scheda{
      height: 300px;
      text-align: center;
      margin: 0px 80px 40px 0;
      left: -20px;
      position: absolute;
      top:-400px;
      background: $blue;
      @media #{$underdesktop} {
        margin: 0px 80px 40px 0;
        top:-500px;
      }
      @media #{$undermd} {
        margin: 0px 20px;
        left: 0px;
      }
      @media #{$undertablet} {
        top:-400px;
      }
      .img-scheda{
        max-width: 80px;
        margin: 0 auto;
      }
      .contenuto{
        top: 50%;
        @include transform(translateY(-50%));
        color: $white;
        text-transform: none;
        background: $blue;
        @media #{$small-phone} {
          top: 0%;
          @include transform(translateY(0%));
        }
      }
    }
  }


}

.modal-dialog{
  top:50%;
  @include transform(translateY(-50%) !important) ;

}

.modal-content{
  text-align: center;
  height: 500px;
  width: 500px;
  border-radius: 50%;
  margin: 0 auto;

  @media #{$small-phone} {
    height: 400px;
    width: 400px;
  }
  @media #{$xsmall-phone} {
    height: 500px;
    width: 300px;
    border-radius: 0%;
  }

  .titolo-sezione{
    font-size: 30px;
    line-height: 35px;
    @media #{$small-phone} {
      font-size: 25px;
    }
  }

  
  .close{
    left: -50%;
    position: relative;
    top: 30px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: $blue;
    color: $white;
    opacity: 1;
    @include transform(translateX(50%) !important) ;
    span{
      font-size: 35px;
      font-weight: 200;
      line-height: 15px;
    }

  }

  .modal-title{
    margin-top: 90px;
  }

  .modal-header{
    padding: 0;
    border:0;
  }

  .modal-body{
    padding: 0px 50px;
  }

  .wpcf7-text, .wpcf7-textarea, .wpcf7-tel {
    width: 100%;
    background: transparent;
    color: $blue !important;
    font-weight: 400;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid transparent;
    border-bottom: 1px solid $grey;
    resize: none;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $blue !important;
  }
  ::-moz-placeholder { /* Firefox 19+ */
      color: $blue !important;
  }
  :-ms-input-placeholder { /* IE 10+ */
      color: $blue !important;
  }
  :-moz-placeholder { /* Firefox 18- */
      color: $blue !important;
  }
  .wpcf7 input,
  .wpcf7 textarea{
    color: $blue;
    font-weight: 400;
  }

  .submit{
    background: $blue;
    text-align: left;
    display: inline-block;
    color: $white !important;
    border:2px solid $blue;
    font-weight: 600;
    border-radius: 20px;
    text-decoration: none;
    padding: 5px 50px 5px 50px;
    margin-top: 20px;
    font-size: 20px;
    position: relative;

  }

  div.wpcf7 .ajax-loader {
    display: none !important;
  }

  .wpcf7-submit:hover{
    background: $black;
    text-align: left;
    display: inline-block;
    color: $white;
    border:2px solid $black;
    font-weight: 600;
    border-radius: 20px;
    text-decoration: none;
    padding: 5px 50px 5px 50px;
    margin-top: 20px;
    font-size: 20px;
    position: relative;

  }
}
