#archivio-news{

  #elenco-news{
    .news{
      margin-bottom: 20px;
    }

    .padding-lato{
      @media #{$undermd} {
        padding: 0px 40px;
      }
    }

    .contenuto{
      background: $white;
      text-align: left;
      padding: 0;
      height: 100%;
      padding: 40px;
      position: relative;
      @media #{$underxl} {
        padding: 20px 40px;
      }
      @media #{$undermd} {
        padding: 40px 40px;
      }

      .cont{
        top:40%;
        position: relative;
        @include transform(translateY(-50%));
        @media #{$undermd} {
          top:0%;
          position: relative;
          @include transform(translateY(0%));

        }
      }

      .social{
        display: inline-block;
        position: relative;
        margin-bottom: 40px;
        @media #{$underxl} {
          margin-bottom: 10px;
        }
        @media #{$undermd} {
          margin-top: 0;
        }
      }
      .data{
        font-family: $font;
        color: $blue;
        font-size: 16px;
      }
      .titolo{
        font-family: $font;
        color: $black;
        font-size: 20px;
        margin-bottom: 20px;
        @media #{$underxl} {
          margin-bottom: 10px;
        }
      }
      .desc{
        font-family: $fontTesti;
        color: $black;
        font-size: 16px;
      }
      .cta-section{
        margin-top: 15px;
        display: inline-block;
        font-weight: 400;
      }
      .link{
        position: relative;
        margin-top: 40px;
        @media #{$underxl} {
          margin-top: 10px;
        }
      }
    }
  }
}

#news{
  #header-page figure figcaption .titolo {
    top:-170px;
    @media #{$undertablet} {
      margin-bottom: -150px;
    }
    @media #{$small-phone} {
      margin-bottom: -50px;
    }
    @media #{$xsmall-phone} {
      margin-bottom: 0px;
    }

  }
  #header-page figure figcaption .titolo {
    @media #{$small-phone} {
      top: -50px;
    }
    @media #{$xsmall-phone} {
      top: -40px;
    }
  }
  #header-page{
    .titolo_news{
      @media #{$small-phone} {
        font-size: 25px;
      }

    }
  }
  .data{
    margin-top: 40px;
    @media #{$xsmall-phone} {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .intro-news{
    border-top: 2px solid $white;
    border-bottom: 2px solid $white;
    padding: 20px 0;
    p{
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
      text-align: center;
      color: $blue;
    }
  }



  .slider-gallery{
    margin-top: -250px;
    @media #{$undermd} {
      margin-top: -150px;
    }
    @media #{$small-phone} {
      margin-top: -80px;
    }
  }

  #descrizione{
    padding: 0 40px 0;
    .contenuto{
      text-align: left;
    }

    .firma{
      margin-top: 40px;
      border-top: 2px solid $lightGrey;
      border-bottom: 2px solid $lightGrey;
      padding: 10px 0 0;

      p{
        color: $blue;
        font-size: 25px;
        font-weight: 400;
        font-family: $font;
      }
    }
  }

  .padd{
    height: 300px;
    @media #{$undermd} {
      height: 200px;
    }
    @media #{$small-phone} {
      height: 100px;
    }
  }

  #titolo-altre{
    padding: 40px 0;
    margin-top: 40px;
  }

  #altreNews{
    border-bottom: 6px solid $blue;
    .blockProd{
      .titolo{
        padding: 20px 0;
        h4{
          font-size: 25px;
          @media #{$small-phone} {
            font-size: 20px;
          }
        }
      }
    }
  }
}
