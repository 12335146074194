#video {
  position: relative;
  overflow: hidden;

  figure#video_clip {
    position: relative;
    height: 100vh;
    overflow: hidden;

    img {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      z-index: -10;
      opacity: 1;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        img {
          height: inherit;
          width: inherit;
          position: absolute;
          top: -9999px;
          right: -9999px;
          bottom: -9999px;
          left: -9999px;
          margin: auto;
          min-width: 100%;
          min-height: 100%;
          z-index: -10;
        }
     }

     @supports (-ms-ime-align:auto) {
       /* IE Edge 12+ CSS styles go here */
        img {
          height: inherit;
          width: inherit;
          position: absolute;
          top: -9999px;
          right: -9999px;
          bottom: -9999px;
          left: -9999px;
          margin: auto;
          min-width: 100%;
          min-height: 100%;
          z-index: -10;
        }
     }



    figcaption {
      position: absolute;
      top: 50%;
      width: 50%;
      @include transform(translate(-50%,-50%));
      color: $white;
      left: 50%;
      @media #{$desktop-xl} {
        left: 100px;
      }
      @media #{$under-desktop-xl} {
        left: 100px;
      }
      @media #{$desktop} {
        left: 50px;
      }
      @media #{$underdesktop} {
        left: 50%;
        @include transform(translate(-50%,-50%));
      }
      @media #{$undermd} {
        width: 100%;
      }
      @media #{$undertablet} {
        left: 0;
        @include transform(translate(0%,-50%));
      }
      @media #{$small-phone} {
        margin-top: 0px;
      }

      .img-dx{
        position: relative;
      }
    }
  }

  iframe {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 90vh;
    @media #{$small-phone} {
      height: 600px;
    }
  }



  #thumbnailyt_container{
    height: 100%;
    overflow: hidden;
    position: relative;
    background: rgba(41,31,28,.5);
  }

  .thumbnailyt {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    height: 90vh;
    z-index: -10;
    width: 100%;
    position: relative;
    @media #{$small-phone} {
      height: 600px;
    }


    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      height: inherit;
      width: inherit;
      position: absolute;
      top: -9999px;
      right: -9999px;
      bottom: -9999px;
      left: -9999px;
      margin: auto;
      min-width: 100%;
      min-height: 100%;
      transform: none;

    }

    @supports (-ms-ime-align:auto) {
     /* IE Edge 12+ CSS styles go here */
     /* IE10+ CSS styles go here */
     height: inherit;
     width: inherit;
     position: absolute;
     top: -9999px;
     right: -9999px;
     bottom: -9999px;
     left: -9999px;
     margin: auto;
     min-width: 100%;
     min-height: 100%;
     transform: none;
    }
  }

  .content-header{
    text-align: center;
    position: relative;
    @media #{$undertablet} {
      top: 50%;
      @include transform(translateY(-50%));
    }

  }

  .box{
    position: relative;
  }

  .start-video {
    position: absolute;
    text-align: center;
    //cursor: pointer;
    transition: all 0.3s;
    top: 50%;
    left: 0;
    @include transform(translateY(-50%));

    .text-video{
      color: $white;
      margin-left: 20px;
      position: relative;
      left: 80px;
      top: -15px;
    }
  }

  .play{
    width: 80px !important;
    height: 80px !important;
  }

  .text-right-video{
    text-align: left;
    h3{
      font-size: 90px !important;
      line-height: 80px;
      font-weight: 800;
      color: $white;
      text-transform: uppercase;
    }

  }

 .fa{
   position: relative;
   font-size: 1.5em;
   color: #fff;
   padding: 10px 25px;
   margin: 10px;
   margin-top: 5px;
   padding: 10px 10px 10px 10px;
   /* border: 1px solid #fff; */
   -moz-border-radius: 50%;
   border-radius: 50%;
  }

  strong{
    color: $white;
    text-transform: uppercase;
    font-size: 25px;
  }

  p.text-video {
    text-align: center;
    font-size: 36px;
    font-weight: 400;
    color: #000000;
    position: absolute;
    top: 65%;
    left: 50%;
    line-height: 50px;
    margin-bottom: 0;
    width: 100%;
    @include transform(translate(-50%,-50%));

    span {
      text-transform: uppercase;
    }
  }
}
