#chi-siamo{
  #descrizione{
    background: $lightGrey;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 80px;
    padding-bottom: 500px;
    @media #{$under-desktop-xl} {
      padding-bottom: 350px;
    }
    @media #{$undermd} {
      padding-bottom: 300px;
    }
    @media #{$small-phone} {
      padding: 0 20px;
      padding-bottom: 300px;
    }
    @media #{$xsmall-phone} {
      padding-bottom: 250px;
    }

    .content{
      text-align: left;
      padding: 0 20px;


      h2{
        font-size: 45px;
        line-height: 50px;
        margin-bottom: 20px;
        @media #{$small-phone} {
          font-size: 25px;
          line-height: 35px;
        }
      }
    }

    .mission{
      text-align: right;
      padding-left: 200px;
      margin-top: 40px;
      @media #{$underxl} {
        padding-left: 100px;
      }
      @media #{$under-desktop-xl} {
        padding-left: 50px;
      }
      @media #{$underdesktop} {
        padding-left: 50px;
        margin-top: 70px;
      }
      @media #{$undermd} {
        padding-left: 0px;
      }
      .citazione{
        font-size: 60px;
        line-height: 65px;
        font-family: $font;
        color: $blue;
        @media #{$underdesktop} {
          font-size: 55px;
          line-height: 65px;
        }
        @media #{$desktop} {
          font-size: 45px;
          line-height: 55px;
        }
        @media #{$undermd} {
          font-size: 45px;
          line-height: 55px;
        }
        @media #{$small-phone} {
          font-size: 25px;
          line-height: 35px;
        }
      }
    }
  }

  #azienda{
    margin-top: 0;
    padding-top: 40px;
    background: transparent;
    text-align: center;
    margin-top: -500px;
    @media #{$under-desktop-xl} {
      margin-top: -350px;
    }
    @media #{$undermd} {
      margin-top: -300px;
    }
    @media #{$xsmall-phone} {
      margin-top: -250px;
    }


    .content{
      margin-bottom: 40px;
    }
  }

  #plus{
    text-align: center;
    padding: 0 40px;
    @media #{$small-phone} {
      padding: 0 20px;
    }

    .content{
      margin-bottom: 40px;
    }
    .elenco-plus{
      margin-top: 40px;
    }

    .plus-box{
      padding: 20px 20px;
      border-right: 1px solid $lightGrey;
      @media #{$undermd} {
        border-right: 0px solid $lightGrey;
      }
      .icona{
        width: 60%;
        @media #{$small-phone} {
          margin: 0 auto;
        }
      }
      .titolo{
        text-align: left;
        @media #{$small-phone} {
          text-align: center;
        }
        h4{
          color: $blue;
          font-size: 25px;
        }
      }
      .descrizione{
        text-align: left;
        @media #{$small-phone} {
          text-align: center;
        }
      }
    }

    .boxx:last-child{
      .plus-box{
        border-right: 1px solid transparent;
      }
    }
  }

  #cta-organizzazione{
    margin-top: 120px;
    margin-bottom: 120px;
    text-align: center;
    @media #{$small-phone} {
      margin-top: 60px;
      margin-bottom: 60px;
      padding: 0 40px;
    }

    a{
      font-size: 40px;
      font-family: $font;
      font-weight: 400;
      color: $grey;
      text-decoration: none;
      border-bottom:4px solid $grey;
      line-height: 55px;
      @media #{$small-phone} {
        font-size: 30px;
        line-height: 40px;
      }
    }
    a:hover{
      font-size: 40px;
      font-family: $font;
      font-weight: 400;
      color: $blue;
      text-decoration: none;
      border-bottom:4px solid $blue;
      line-height: 55px;
      @media #{$small-phone} {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }

}
