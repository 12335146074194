#archivio{

  #categorie{
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #descrizione{
    text-align: center;
    background: $lightGrey;
    padding-bottom: 40px;
  }
  .box-prodotto{
    margin-bottom: 30px;
    a{
      text-decoration: none;
    }
     border:2px solid $lightGrey;
     border-bottom: 6px solid $blue;
     figure{
       transition: all 0.3s ease;
       -webkit-transition: all 0.3s ease;
       -o-transition: all 0.3s ease;
       -moz-transition: all 0.3s ease;
       background: $white;
     }
     h4{
       font-size: 30px;
       -webkit-transform: translate3d(0,0px,0);
       transform: translate3d(0,0px,0);
       -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
     }

     .block{
       .link-category{
         opacity: 0;
         border-radius: 50%;
         padding: 5px 15px;
         font-size: 25px;
         top: 20px;
         display: inline-block;
         position: relative;
         -webkit-transform: translate3d(0,0px,0);
         transform: translate3d(0,0px,0);
         transition: opacity 0.5s, transform 0.5s;
         background: $white;
         color: $white;
       }
     }

   }
  .box-prodotto:hover{
     border:2px solid $lightGrey;
     border-bottom: 6px solid $blue;

     figure{
       background: $blue;
       transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
     }

     h4{
       font-size: 30px;
       color: $white;
       -webkit-transform: translate3d(0,-120px,0);
       transform: translate3d(0,-120px,0);
       -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
     }

     .block{
       .link-category{
         opacity: 1;
         color: $blue;
         border-radius: 50%;
         background: $white;
         padding: 5px 15px;
         font-size: 25px;
         top: 20px;
         display: inline-block;
         position: relative;
         transition: opacity 0.5s, transform 0.5s;
         -webkit-transform: translate3d(0,-120px,0);
         transform: translate3d(0,-120px,0);
         -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
       }
       img{
         opacity: 0;
         -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
       }
     }

   }
}
