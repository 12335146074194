/************ STILE TEMA ************/
.site-content{
  margin-left: 20px;
  margin-right: 20px;
  @media #{$undermd} {
    margin-left: 10px;
    margin-right: 10px;
  }

}

.description{
  font-family: $fontTesti;
  color: $black;
}

#descrizione{
  .content{
    @media #{$small-phone} {
      padding: 0 60px;
    }
  }
}

.blue{
  color: $blue;
}

.black{
  color: $black;
}

.white{
  color: $white;
}


.centered{
  text-align: center;
}

.center-cropped {
  width: 100%;
  height: auto;
  overflow: hidden;
  max-height: 250px;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    @include transform(translate(-50%,-50%));
    width: auto;
    height: 100%;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.horizontal-cropped {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.horizontal-cropped img {
  display: block;
  width: 100vw;
  height: 100%;
  object-fit: cover;
}


.bg-grey{
  background: $lightGrey;
}

//fontTesti
.font20{
  font-size: 20px;
  line-height: 25px;
}

.font30{
  font-size: 30px;
  line-height: 35px;
}

.font35{
  font-size: 35px;
  line-height: 40px;
}

//padding
.padding-lato{
  padding: 0 100px;
  @media #{$undertablet} {
      padding: 0 40px;
  }
  @media #{$small-phone} {
      padding: 0 20px;
  }
}
.padding-bottom-section{
  padding-bottom: 40px;
}

.remove-padding{
  padding: 0;
}

.padding-top-bottom{
  padding: 60px 0;
}

//titoli
.titolo-sezione{

  position: relative;
  display: inline-block;
  h3, h2{
    text-align: center;
    font-size: 45px;
    line-height: 50px;

    @media #{$small-phone} {
      font-size: 25px;
      line-height: 35px;
      margin-bottom: 10px;
    }
  }

}


.titolo-sezione:before{
  content: "•";
  color: $blue;
  display: inline-block;
  width: .1em;
  font-size: 40px;
  line-height: 10px;
  position: absolute;
  top: 50%;
  @include transform (translateY(-50%));
  margin-right: 0px;
  left: -20px;


}
.titolo-sezione:after{
  content: "•";
  color: $blue;
  display: inline-block;
  width: .1em;
  font-size: 40px;
  line-height: 10px;
  position: absolute;
  top: 50%;
  @include transform (translateY(-50%));
  margin-left: 0px;
  right: -10px;

}

//cta
.cta-header{
  background: $blue;
  color: $white;
  border-radius: 40px;
  padding: 10px 40px;
  position: relative;
  text-decoration: none;
  display: inline-block;
  font-family: $font;
  font-size: 25px;
  letter-spacing: 1px;

}
.cta-header:hover{
  background: $black;
  color: $white;
  border-radius: 40px;
  padding: 10px 40px;
  position: relative;
  text-decoration: none;
  font-family: $font;
  font-size: 25px;
  letter-spacing: 1px;
}

.cta-section{
  background: $blue;
  color: $white;
  border-radius: 40px;
  padding: 5px 20px;
  position: relative;
  text-decoration: none;
  font-family: $font;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 400;
  margin-top: 10px;
  display: inline-block;
}
.cta-section:hover{
  background: $black;
  color: $white;
  border-radius: 40px;
  padding: 5px 20px;
  position: relative;
  text-decoration: none;
  font-family: $font;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1px;
  margin-top: 10px;
}


//sezioni
.margin-section{
  margin: 40px 0;
  @media #{$desktop-xl} {
    margin: 40px 0;
  }
}

//figure-figcaption
figure.img-sfondo {
  position: relative;
  width: 100%;
  height: 100vh;
  @media #{$undertablet} {
    height: 135vh;
  }
  @media #{$small-phone} {
    height: 100%;
  }

  img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -10;
    opacity: 1;

  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      img {
        height: inherit;
        width: inherit;
        position: absolute;
        top: -9999px;
        right: -9999px;
        bottom: -9999px;
        left: -9999px;
        margin: auto;
        min-width: 100%;
        min-height: 100%;
        z-index: -10;
      }
   }

   @supports (-ms-ime-align:auto) {
     /* IE Edge 12+ CSS styles go here */
      img {
        height: inherit;
        width: inherit;
        position: absolute;
        top: -9999px;
        right: -9999px;
        bottom: -9999px;
        left: -9999px;
        margin: auto;
        min-width: 100%;
        min-height: 100%;
        z-index: -10;
      }
   }

   .cover{
     height: 100vh;
     @media #{$undertablet} {
       height: 135vh;
     }
     @media #{$small-phone} {
       display: none;
     }
   }

  figcaption {
    position: absolute;
    top: 50%;
    @include transform(translate(-50%,-50%));
    color: $white;
    left: 50%;
    text-align: center;
    @media #{$undertablet} {
      top:0%;
      @include transform(translateY(0%));
      @include transform(translateX(-50%));
      width: 100%;
      padding: 40px;
    }
    @media #{$small-phone} {
      position: relative;
      background: $black;
      margin-bottom: 20px;
      display: block;
      top:0%;
      @include transform(translateY(0%));
      @include transform(translateX(-50%));
      padding-bottom: 80px;
    }
  }
}

//button

.cta-button{
  background: $orange;
  padding: 15px 40px 15px 40px;
  color: $white;
  border-radius: 40px;
  text-decoration: none;
  position: relative;
  margin-top: 20px;
  display: inline-block;
  font-size: 25px;
  font-family: $font;
  font-weight: 400;
  letter-spacing: 1px;

}
.cta-button:hover, .cta-button:focus{
  background: $white;
  padding: 15px 40px 15px 40px;
  color: $orange;
  border-radius: 40px;
  text-decoration: none;
  position: relative;
  margin-top: 20px;
  display: inline-block;
  font-size: 25px;
  font-family: $font;
  font-weight: 400;
  letter-spacing: 1px;

}

.virgoletta{
  font-family: $font;
  font-size: 160px;
  line-height: 0;
  font-weight: 400;
  color: $grey;
  @media #{$undermd} {
    font-size: 120px;
    margin: 0;
  }
}

.img-responsive{
  width: 100%;
}

.slick-dots{
  width: auto;
  display: inline-block;
  position: relative;

}

.slider-arrow, .slick-news, .slider-gallery, .slider-news {
  position: relative;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 3;
  text-align: center;


  .slider-nav {
    display: inline-block;
    top:10px;
    position: relative;
    @media #{$undermd} {
      top: 250px;
    }
    @media #{$tablet} {
      top: 200px;
    }
    @media #{$small-phone} {
      top: 220px;
    }
    @media #{$xsmall-phone} {
      top: 170px;
    }
    @media #{$xxsmall-phone} {
      top: 140px;
    }

    .slick-dots {
      margin: 0 10px;
      position: relative;
      top: 4px;
      bottom: auto;
      width: auto;
      height: 10px;
      display: inline-block;
      li {
        position: relative; top: -10px;
        button {
          background-color: transparent;
        }
      }
    }

    .slick-prev, .slick-next {
      position: relative;
      left: auto;
      right: auto;
      display: inline-block;
      color: $grey;
      content:"\f053";
      font-family: FontAwesome;
    }
    .slick-next:before {
     position: relative;
     left: auto;
     right: auto;
     display: inline-block;
     color: $grey;
     content:"\f054";
     font-family: FontAwesome;
     top:15px;
    }
    .slick-prev:before {
     position: relative;
     left: auto;
     right: auto;
     display: inline-block;
     color: $grey;
     content:"\f053";
     font-family: FontAwesome;
     top:15px;
    }
    .slick-prev:before {
      float: left;
    }
    .slick-next {
      float: right;
    }

    .slick-track{
      @media #{$undermd} {
        margin-top: -250px;
      }
    }

    button{
      width: 10px;
      height: 10px;
      cursor: pointer;
      background: transparent;
      border:1px solid $grey;
      border-radius: 10px;
    }

    button:hover{
      width: 10px;
      height: 10px;
      cursor: pointer;
      background: $grey;
      border:1px solid $grey;
      border-radius: 10px;
    }

    button:before {
      opacity: .75;
      color: transparent !important;
    }
  }
  .slick-active button {
    width: 10px;
    height: 10px;
    cursor: pointer;
    background: $grey !important;
    border: 1px solid $grey;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
}

.social{
  margin-top: 40px;
  @media #{$small-phone} {
    margin-top: 5px;
  }
  .jssocials-share {
      display: inline-block;
      margin: .3em .6em .3em 0;
  }

  .jssocials-share-link {
    padding: .4em .5em;
    background: $grey;
    border-radius: 50%;

    @media #{$small-phone} {
      padding: .2em .3em;
    }
  }
  .jssocials-share-link:hover {
    padding: .4em .5em;
    background: $blue;
    border-radius: 50%;
    @media #{$small-phone} {
      padding: .2em .3em;
    }
  }

  .jssocials-share-logo {
    width: 1em;
    vertical-align: middle;
    font-size: 1em;
    @media #{$small-phone} {
      font-size: 15px;
      top: -1px;
      position: relative;
    }
  }
}

.link-news{
  color: $white;
  background: $blue;
  -moz-border-radius: 40px;
  border-radius: 40px;
  padding: 5px 20px;
  position: relative;
  text-decoration: none;
  font-family: $font;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 400;
  margin-top: 10px;
  display: inline-block;
}
.link-news:hover{
  color: $white;
  background: $black;
  -moz-border-radius: 40px;
  border-radius: 40px;
  padding: 5px 20px;
  position: relative;
  text-decoration: none;
  font-family: $font;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 400;
  margin-top: 10px;
  display: inline-block;
}
