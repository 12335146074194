#organizzazione{
  #paragrafo{
    background: $lightGrey;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;

    padding: 0 40px;
    padding-bottom: 60px;
  }
  #descrizione{
    background: $lightGrey;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 80px;
    @media #{$small-phone} {
      padding: 0 20px;
    }

    .content{
      text-align: left;
      @media #{$small-phone} {
        padding: 0 0px;
      }

      h2{
        font-size: 45px;
        line-height: 50px;
        margin-bottom: 20px;
        @media #{$small-phone} {
          font-size: 30px;
          line-height: 40px;
        }
      }
    }

    img{
      @media #{$underxl} {
        margin-top: 60px;
      }
      @media #{$under-desktop-xl} {
        margin-top: 60px;
      }
      @media #{$desktop} {
        margin-top: 60px;
      }
      @media #{$small-phone} {
        margin-top: 0px;
      }
    }

    .mission{
      text-align: right;
      padding-left: 100px;
      margin-top: 100px;
      @media #{$underxl} {
        padding-left: 100px;
      }
      @media #{$under-desktop-xl} {
        padding-left: 50px;
      }
      @media #{$underdesktop} {
        padding-left: 50px;
        margin-top: 70px;
      }
      @media #{$undermd} {
        padding-left: 0px;
      }

      .citazione{
        font-size: 60px;
        line-height: 65px;
        font-family: $font;
        color: $blue;
        @media #{$underdesktop} {
          font-size: 55px;
          line-height: 65px;
        }
        @media #{$desktop} {
          font-size: 45px;
          line-height: 55px;
        }
        @media #{$undermd} {
          font-size: 45px;
          line-height: 55px;
        }
        @media #{$small-phone} {
          font-size: 30px;
          line-height: 40px;
        }
      }
    }
  }

  #plus{
    text-align: center;
    padding-bottom: 40px 0px;
    background: $lightGrey;

    .content{
      margin-bottom: 60px;
    }
    .elenco-plus{
      margin-top: 40px;
    }

    .plus-box{
      padding: 20px 20px;
      border-right: 1px solid $lightGrey;
      .icona{
        width: 60%;
      }
      .titolo{
        text-align: left;
        h4{
          color: $blue;
          font-size: 25px;
        }
      }
      .descrizione{
        text-align: left;
      }
    }

    .boxx:last-child{
      .plus-box{
        border-right: 1px solid transparent;
      }
    }
  }

  #galleryTitolo{
    text-align: center;
  }




}
