footer{
  z-index: 1;
  position: relative;
  @media #{$undertablet} {
    margin-top: 10px;
  }

  .box{
    position: relative;
    left: 50%;
    width: 100%;
    @include transform(translateX(-50%));
    display: inline-block;
    margin-left: 80px;
    @media #{$under-desktop-xl} {
      margin-left: 0px;
    }
    @media #{$undermd} {
      margin-left: 0px;
    }
    @media #{$undertablet} {
      width: 100%;
      padding-left: 40px;
    }

  }

  .bordered{
    .col-xs-4{
      padding: 0 !important;
    }
  }

  .lista{
    border-right: 1px solid $lightGrey;
    padding: 30px 0;
    @media #{$under-desktop-xl} {
      padding: 20px 20px;
    }
    @media #{$tablet} {
      padding: 0px 0;
      text-align: center;
    }
    @media #{$undertablet} {
      padding: 0px 0;
      text-align: center;
    }

    a{
      @media #{$tablet} {
        position: relative;
        display: block;
        font-weight: 700;
        padding: 10px 0;
      }
      @media #{$undertablet} {
        position: relative;
        display: block;
        font-weight: 700;
        padding: 10px 0;
      }
    }

    p{
      margin: 0;
      font-size: 15px;
      @media #{$xsmall-phone} {
        font-size: 14px;
      }
    }

    img{
      @media #{$tablet} {
        width: 150px;
        margin: 0 auto;
        top: -10px;
        position: relative;
      }
      @media #{$undertablet} {
        width: 150px;
        margin: 0 auto;
        top: -10px;
        position: relative;
      }
    }

    .titolo{
      text-align: left;
      margin-bottom: 10px;

      a{
        text-decoration: none;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 20px;
      }
      a:hover{
        color: $blue;
        font-weight: 600;
        font-size: 20px;
      }
    }

    ul{
      list-style: none;
      li{
        a{
          margin-left: 20px;
          position: relative;
          text-decoration: none;
          font-weight: 300;
          display: inline-block;
          width: 100%;
        }
        a:hover{
          color: $blue;
        }
      }
      li:before {
        content: "•";
        color: $blue;
        display: inline-block;
        width: .1em;
        margin-left: -1.3em;
        font-size: 30px;
        line-height: 10px;
        left: 0;
        position: relative;
        right: -50px;
        top: 6px;
      }
    }
  }
  .center-list{
    border-left:1px solid $lightGrey;
    border-right:1px solid $lightGrey;
  }
  .contatti{
    padding: 30px 0;
    @media #{$tablet} {
      padding: 10px 0;
      text-align: center;
      padding-top: 5px;
    }
    @media #{$undertablet} {
      padding: 10px 10px;
      text-align: center;
      padding-top: 5px;
    }
    span{
      @media #{$xsmall-phone} {
        font-size: 14px;
      }
    }

    .email:before{
      @media #{$undermd} {
        content: "•";
        color: $blue;
        display: inline-block;
        width: .1em;
        font-size: 30px;
        line-height: 10px;
        left: 0;
        position: relative;
        right: -50px;
        top: 6px;
        margin-right: 15px;
        margin-left: 5px;
      }
    }

    .titolo{
      text-align: left;
      margin-bottom: 10px;
      a{
        text-decoration: none;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 20px;

      }
      a:hover{
        color: $blue;
        font-weight: 600;
        font-size: 20px;
      }
    }
    ul{
      list-style: none;
      li{
        font-size: 18px;
        span{
          font-size: 18px;
          margin-left: 20px;
          position: relative;
          text-decoration: none;
          font-weight: 300;
          display: inline-flex;
        }
      }
      li:before {
        content: "•";
        color: $blue;
        display: inline-block;
        width: .1em;
        margin-left: -1.3em;
        font-size: 30px;
        line-height: 10px;
        left: 0;
        position: relative;
        right: -50px;
        top: 6px;
      }
    }

    .img-logo-icea{
      width: 200px;
      margin-top: -20px;
    }
  }
  .mappa-sito{
    padding: 10px 0;
    background: $white;
    color: $black;
    @media #{$undertablet} {
      padding: 0px 0;
    }
  }
  .loghi{
    background: $lightGrey;
    padding: 20px 0;
    span{
      font-size:18px;
      bottom: 0px;
      position: relative;
    }

    .certificazione{
      position: relative;

      @media #{$undermd} {
          text-align: center;
      }

      span{
        font-size: 15px;
        @media #{$xsmall-phone} {
          font-size: 14px;
        }
      }
    }

    .img-logo-footer-dx{
      width: 200px;
      float: left;
      @media #{$undermd} {
        float: left;
      }
    }
    .img-logo-footer-sx{
      width: 200px;
      float: right;
      position: relative;
      top:50%;
      @include transform(translateY(-50%));
      @media #{$tablet} {
        top:0%;
        @include transform(translateY(0%));
        float: right;
      }
      @media #{$undertablet} {
        top:0%;
        @include transform(translateY(0%));
        float: right;
      }
    }
  }
  .certificazione{
    position: relative;
    .img-logo-certificazione{
      text-align: center;
      @media #{$undermd} {
        width: 100px;
        margin: 0 auto;
      }
    }
  }
  .copyright{
    background: $blue;
    color: $white;
    text-align: center;
    padding: 10px 0;

    a{
      font-size: 10px;
      color: $white;
      text-decoration: none;
    }
    a:hover{
      font-size: 10px;
      color: $black;
      text-decoration: none;
    }
  }
}
