/******** HOME PAGE *********/
#header-home{
  position: relative;
  @media #{$undermd} {
    margin-top: -70px !important;
  }

  .container{
    position: relative;
    top:50%;
  }
  figure {
    position: relative;
    width: 100%;
    height: 80vh;
    //background: rgba(41,31,28,.3);
    @media #{$undermd} {
      margin-top: -70px !important;
    }
    @media #{$small-phone} {
      height: 800px;
    }

    .img-cover {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      width: 100%;
      height: 100vh;
      z-index: -10;
      opacity: 1;
      position: relative;
      top: -100px;

      @media #{$small-phone} {
        height: 800px;
        top: 0px;
      }

    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        .img-cover {
          height: inherit;
          width: inherit;
          position: absolute;
          top: -9999px;
          right: -9999px;
          bottom: -9999px;
          left: -9999px;
          margin: auto;
          min-width: 100%;
          min-height: 100%;
          z-index: -10;
        }
     }

     @supports (-ms-ime-align:auto) {
       /* IE Edge 12+ CSS styles go here */
        .img-cover {
          height: inherit;
          width: inherit;
          position: absolute;
          top: -9999px;
          right: -9999px;
          bottom: -9999px;
          left: -9999px;
          margin: auto;
          min-width: 100%;
          min-height: 100%;
          z-index: -10;
        }
     }

    figcaption {
      position: absolute;
      top: 50%;
      width: 100%;
      @include transform(translate(-50%,-50%));
      color: $white;
      left: 50%;
      text-align: center;
      height: 100%;
      @media #{$undertablet} {
        width: 100% !important;
      }
      @media #{$small-phone} {
        top: 40%;
      }

      .pagina{
        position: relative;
        top: 50%;
        display: block;
        width: 100%;
        left: 50%;
        @include transform(translate(-50%,-50%));
        padding: 0 10px;
        @media #{$small-phone} {
          top: 50%;
        }
      }

      .titolo{
        display: inline-block;
        position: relative;
        text-align: left;
        @media #{$undermd} {
          text-align: left;
        }

        h1{
          padding-bottom: 10px;
          font-size: 60px;
          @media #{$small-phone} {
            font-size: 25px;
          }
        }
        h2{
          font-size: 45px;
          margin-bottom: 0;
          @media #{$small-phone} {
            font-size: 35px;
          }
        }
      }
    }
  }
}

#header-page{
  @media #{$undermd} {
    margin-top: -70px !important;
  }
  figure{
    background: $lightGrey;
    min-height: 300px;
    position: relative;
    @media #{$small-phone} {
      min-height: 200px;
    }

    figcaption{
      position: relative;

      .titolo{
        left:50%;
        position: relative;
        width: 80%;
        @include transform(translateX(-50%));
        top: -200px;
        margin-bottom: -150px;
        @media #{$undermd} {
          top: -150px;
          margin-bottom: -100px;
        }
        @media #{$small-phone} {
          top:-65px;
          position: relative;
          display: block;
          margin-bottom: -50px;
        }
        @media #{$xxsmall-phone} {
          top:-40px;
        }
      }

      .titolo_header{
        font-size: 90px;
        font-family: $font;
        font-weight: 400;
        color: $blue;
        text-align: center;
        text-transform: uppercase;
        @media #{$tablet} {
          font-size: 70px;
        }
        @media #{$undertablet} {
          font-size: 50px;
        }
        @media #{$small-phone} {
          font-size: 35px;
          line-height: 45px;
          margin-top: 20px;
          margin-bottom: 40px;
        }
        @media #{$xsmall-phone} {
          font-size: 27px;
          line-height: 35px;
          margin-top: 20px;
          margin-bottom: 40px;
        }
      }
    }
  }


  .tondo{
    position: relative;
    max-width: 600px;
    left: 50%;
    @include transform(translateX(-50%));
    @media #{$small-phone} {
      max-width: 400px;
    }
  }
}
