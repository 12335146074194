#archivio-prodotti{
  #descrizione{
    text-align: center;
    background: $lightGrey;
    padding-bottom: 40px;
  }

  #categorie{
    margin-top: 20px;
    margin-bottom: 20px;

    .box-prodotti{
      background: $lightGrey;
      border-bottom: 8px solid $blue;

      .block{
        padding: 0 60px 40px 20px;
        text-align: right;

        @media #{$desktop} {
          padding: 0 40px 40px 0px;
        }
      }

      img{
        transition: all 0.6s ease;
        -webkit-transition: all 0.6s ease;
        -o-transition: all 0.6s ease;
        -moz-transition: all 0.6s ease;
      }

      h4{
        font-size: 25px;
        text-align: right;
      }

      h4:after{
        content: "•";
        color: $blue;
        display: inline-block;
        width: 0em;
        font-size: 45px;
        line-height: 10px;
        left: 0;
        position: relative;
        left: 15px;
        top: 8px;
      }

      a:hover{
        color: $black;
      }

      .link-category{
        border-radius: 50%;
        padding: 5px 14px;
        color: $white;
        font-size: 25px;
        background: $blue;
        float: right;
        margin-right: 20px;
        margin-top: -20px;
        display: block;
        position: relative;
        margin-bottom: 20px;
      }
    }
    .box-prodotti:hover{
      background: $lightGrey;
      border-bottom: 8px solid $grey;



      .link-category{
        border-radius: 50%;
        padding: 5px 14px;
        color: $white;
        font-size: 25px;
        background: $grey;
        float: right;
        margin-right: 20px;
        margin-top: -20px;
        display: block;
        position: relative;
        margin-bottom: 20px;
      }

      h4:after{
        content: "•";
        color: $grey;
        display: inline-block;
        width: 0em;
        font-size: 45px;
        line-height: 10px;
        left: 0;
        position: relative;
        left: 15px;
        top: 8px;
      }
    }
  }
}
