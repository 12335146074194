.box-category-home{
  background: $lightGrey;
  border-bottom: 8px solid $blue;
  @media #{$undertablet} {
    margin-bottom: 20px;
  }

  figcaption{
    margin-top: -100px;
  }
  .contenuto{
    padding: 0 60px 40px 0;
    text-align: right;
    @media #{$small-phone} {
      padding: 0 20px;
      padding-left: 10px;
    }

    h4{
      font-size: 25px;
    }

    h4:after{
      content: "•";
      color: $blue;
      display: inline-block;
      width: 0em;
      font-size: 45px;
      line-height: 10px;
      left: 0;
      position: relative;
      left: 15px;
      top: 8px;
      @media #{$small-phone} {
        display: none;
      }
    }

    .icona{
      width: 100px;
      text-align: right;
      display: inline-block;
      margin-bottom: 10px;
      @media #{$small-phone} {
        width: 60px;
        margin-top: 40px;
      }
    }

    .block{
      padding-left: 200px;
      @media #{$undermd} {
        padding-left: 100px;
      }
      @media #{$small-phone} {
        padding-left: 50px;
      }
    }

    .link-category{
      border-radius: 50%;
      padding: 5px 14px;
      color: $white;
      font-size: 25px;
      background: $blue;
      float: left;
      margin-left: 40px;
      margin-top: -80px;
      @media #{$small-phone} {
        margin-left: 0px;
        padding: 4px 10px;
        font-size: 20px;
        margin-top: -60px;
      }
    }
  }
}

.box-category-home:hover{
  background: $lightGrey;
  border-bottom: 8px solid $grey;
  cursor: pointer;

  h4:after{
    content: "•";
    color: $grey;
    display: inline-block;
    width: 0em;
    font-size: 45px;
    line-height: 10px;
    left: 0;
    position: relative;
    left: 15px;
    top: 8px;
    @media #{$small-phone} {
      display: none;
    }
  }
  a{
    color: $black;
  }
  .link-category{
    border-radius: 50%;
    padding: 5px 14px;
    color: $white;
    font-size: 25px;
    background: $grey;
    float: left;
    margin-left: 40px;
    margin-top: -80px;
    @media #{$small-phone} {
      margin-left: 0px;
      padding: 4px 10px;
      font-size: 20px;
      margin-top: -60px;
    }
  }
}

#news-slider{
  margin-bottom: 60px;
  @media #{$undertablet} {
    margin-bottom: -160px;
  }
  @media #{$small-phone} {
    margin-bottom: 0px;
  }
  .contenuto{
    background: $lightGrey;
    border-radius: 50%;
    padding: 40px 20px 60px 60px;
    text-align: left;
    left: -150px;
    margin-top: 15%;
    width: 450px;
    height: 450px;
    @media #{$under-desktop-xl} {
      margin-top: 30%;
      width: 400px;
      height: 400px;
    }
    @media #{$undermd} {
      margin-top: 5%;
      width: 380px;
      height: 380px;
      padding: 25px 20px 60px 60px;
    }
    @media #{$undertablet} {
      height: 400px;
      width: 400px;
      top: -200px;
      margin: 0 auto;
      left: 0px;
    }
    @media #{$small-phone} {
      text-align: center;
      height: 350px;
      width: 350px;
      top: -100px;
      padding: 20px 20px 60px 20px;
    }
    @media #{$xsmall-phone} {
      text-align: center;
      height: 270px;
      width: 270px;
      padding: 20px 20px 60px 20px;
    }


    .social{
      margin-bottom: 20px;
      display: inline-block;
      @media #{$xsmall-phone} {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
    .data{
      font-family: $font;
      color: $blue;
      font-size: 16px;
    }
    .title{
      font-family: $font;
      color: $black;
      font-size: 20px;
      margin-bottom: 20px;
      @media #{$small-phone} {
        font-size: 16px;
        margin-bottom: 5px;
      }
    }
    .desc{
      font-family: $fontTesti;
      color: $black;
      font-size: 16px;
    }
    .link-news{
      margin-top: 20px;
      display: inline-block;
      font-weight: 400;
    }
  }

  .immagine{
    position: relative;
    overflow: hidden;
    /* Width and height can be anything. */
    width: 520px;
    height: 520px;
    border-radius: 50%;
    margin-left: 40px;

    @media #{$under-desktop-xl} {
      height: 520px;
      width: 520px;
      margin-left: 0px;
    }
    @media #{$undermd} {
      height: 400px;
      width: 400px;
    }
    @media #{$undertablet} {
      height: 500px;
      width: 500px;
      float: none;
      margin: 0 auto;
    }
    @media #{$small-phone} {
      height: 380px;
      width: 380px;
      float: none;
      margin: 0 auto;
    }
    @media #{$xsmall-phone} {
      height: 280px;
      width: 280px;
      float: none;
      margin: 0 auto;
    }

    .img-cover {

      position: absolute;
      /* Position the image in the middle of its container. */
      top: -9999px;
      right: -9999px;
      bottom: -9999px;
      left: -9999px;
      margin: auto;
      /* The following values determine the exact image behaviour. */
      /* You can simulate background-size: cover/contain/etc.
         by changing between min/max/standard width/height values.
         These values simulate background-size: cover
      */
      min-width: 50%;
      min-height: 50%;
      height: 520px;
      @media #{$under-desktop-xl} {
        height: 520px;
      }
      @media #{$undermd} {
        height: 400px;
      }
      @media #{$undertablet} {
        height: 500px;
      }
      @media #{$small-phone} {
        height: 380px;
      }
      @media #{$xsmall-phone} {
        height: 280px;
      }
    }
  }

}
