.slider-home{
  margin-bottom: 10px;
}
.slider-link{
  text-align: center;
  top: -80px;
  width: 170px;
  left: 50%;
  z-index: 999;
  @include transform(translateX(-50%));

  .cta-header{
    z-index: 1;
    position: relative;
  }
}


.slider-track{
  width: 500px !important;
  margin: 0 auto;
  z-index: 1;
  text-align: center;
  top: -137px;
  margin-bottom: -147px;
  overflow: hidden;

  @media #{$small-phone} {
    width: 300px !important;
  }

  .slick-track{
    margin: 0 auto;
  }

  .slick-list{
    overflow: visible;
  }

  // .slick-current{
  //   left: 150px;
  //   position: relative;
  // }

  .slick-active{
    .span-div:after{
      color: $white;
      width: 50px;
      background: transparent;
      height: 1px;
      content: "";
      position: absolute;
      left: 15px;
      top:29px;
      @media #{$small-phone} {
        width: 30px;
      }
    }
    .span-div:before{
      width: 50px;
      background: $white;
      height: 1px;
      content: "";
      position: absolute;
      right: 40px;
      top:29px;
      @media #{$small-phone} {
        width: 30px;
      }
    }
  }
  .slick-current{
    .span-div:before{
      width: 50px;
      background: transparent;
      height: 1px;
      content: "";
      position: absolute;
      right: 15px;
      top:29px;
      @media #{$small-phone} {
        width: 30px;
      }
    }
    .span-div:after{
      color: $white;
      width: 50px;
      background: $white;
      height: 1px;
      content: "";
      position: absolute;
      left: 40px;
      top:29px;
      @media #{$small-phone} {
        width: 30px;
      }
    }

    .span-div:hover{
      span:before{
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
      }
    }

  }
  .slick-prev, .slick-next{
    display: none !important;
  }

  .span-div{
    height: 80px;
    position: relative;

    span{
      top: 20px;
      @media #{$small-phone} {
        display: none;
      }
    }

    span:before{
      content: "";
      position: absolute;
      top: 0;
      left: 1px;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      top:3px;
      margin-left: -19px;
      margin-top: -19px;
      border: 3px solid $white;
      background: transparent;
      -webkit-transform: scale(.25);
      -ms-transform: scale(.25);
      transform: scale(.25);
      display: block;
      -webkit-transition: -webkit-transform .3s cubic-bezier(.4,0,0,1);
      transition: -webkit-transform .3s cubic-bezier(.4,0,0,1);
      transition: transform .3s cubic-bezier(.4,0,0,1);
      transition: transform .3s cubic-bezier(.4,0,0,1),-webkit-transform .3s cubic-bezier(.4,0,0,1);
    }
  }

  .span-div:hover{
    span:before{
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }



  .slide{
    i{
      top:60px;
    }
  }

  span{
    color: $white;
    z-index: 0;
    position: relative;
  }

}
