#contatti{
  #contattaci{
    background: $lightGrey;
    padding: 0 100px;
    @media #{$underdesktop} {
      padding: 0 60px;
    }
    @media #{$undertablet} {
      padding: 0 40px;
    }
    @media #{$small-phone} {
      padding: 0 40px;
    }
    .wpcf7{
      margin-top: 40px;
      color: $blue;
      @media #{$small-phone} {
        padding: 0px!important;
        margin: 0px!important;
      }

      .wpcf7-text, .wpcf7-textarea, .wpcf7-tel {
        width: 100%;
        background: transparent;
        color: $blue !important;
        font-weight: 400;
        margin-bottom: 20px;
        padding: 10px;
        font-size: 18px;
        border: 1px solid transparent;
        border-bottom: 1px solid $grey;
        resize: none;
      }
      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $blue !important;
      }
      ::-moz-placeholder { /* Firefox 19+ */
          color: $blue !important;
      }
      :-ms-input-placeholder { /* IE 10+ */
          color: $blue !important;
      }
      :-moz-placeholder { /* Firefox 18- */
          color: $blue !important;
      }
      .wpcf7 input,
      .wpcf7 textarea{
        color: $blue;
        font-weight: 400;
      }

      .privacy{
        @media #{$undertablet} {
          text-align: center;
        }
      }

      .wpcf7-form{
        margin-bottom: 60px;
      }

      div.wpcf7-response-output{
        margin: 0;
      }

      .submit{
        background: $orange;
        text-align: left;
        display: inline-block;
        color: $white;
        border:2px solid $orange;
        font-weight: 600;
        border-radius: 20px;
        text-decoration: none;
        padding: 5px 50px 5px 50px;
        margin-top: 20px;
        font-size: 20px;
        left: 50%;
        position: relative;
        @include transform(translateX(-50%));

      }

      .wpcf7-submit:hover{
        background: $white;
        text-align: left;
        display: inline-block;
        color: $orange;
        border:2px solid $orange;
        font-weight: 600;
        border-radius: 20px;
        text-decoration: none;
        padding: 5px 50px 5px 50px;
        margin-top: 20px;
        left: 50%;
        font-size: 20px;
        position: relative;
        @include transform(translateX(-50%));

      }
    }
    ul{
      list-style: none;
      padding-left: 0;
      margin-left: 30px;
      li{
        font-size: 18px;
        margin: 10px 0;
        display: block;
        @media #{$underxl} {
          display: inline-flex;
        }
        @media #{$underdesktop} {
          display: block;
        }
        a{
          @media #{$underdesktop} {
            display: block;
          }
          @media #{$undermd} {
            display: inline-block;
          }
        }
      }
      li:before {
        content: "•";
        color: $blue;
        display: inline-block;
        width: .1em;
        margin-left: -20px;
        font-size: 30px;
        line-height: 10px;
        left: -20px;
        position: relative;
        right: 0px;
        top: 6px;
      }
    }
  }

  .form{
    @media #{$undermd} {
      margin-top: 40px;
    }
  }
  #mappaTitolo{
    text-align: center;
    margin: 40px 0;
    padding: 0 40px;
  }
  #mappa{
    border-bottom: 6px solid $blue;
  }
}
