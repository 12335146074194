#archivio-applicazioni{
  #descrizione{
    text-align: center;
    background: $lightGrey;
    padding-bottom: 40px;
  }

  #categorie{
    margin-top: 20px;
    margin-bottom: 20px;

    .box-applicazione{
      background: $lightGrey;
      border-bottom: 8px solid $blue;
      @media #{$undermd} {
        margin-bottom: 30px;
      }

      figcaption{
        margin-top: -100px;
      }

      .block{
        padding: 0 60px 40px 0;
        text-align: right;
        @media #{$underxl} {
          padding: 0 35px 40px 0;
        }
        @media #{$desktop} {
          padding: 0 30px 40px 0;
        }
      }

      img{
        transition: all 0.6s ease;
        -webkit-transition: all 0.6s ease;
        -o-transition: all 0.6s ease;
        -moz-transition: all 0.6s ease;
      }

      .icona{
        width: 100px;
        text-align: right;
        display: inline-block;
        margin-bottom: 10px;
        @media #{$underxl} {
          width: 80px;
          margin-top: 20px;
        }
        @media #{$underdesktop} {
          width: 80px;
          margin-top: 60px;
        }
      }

      a:hover{
        color: $black;
      }

      h4{
        font-size: 25px;
        text-align: right;
        @media #{$underxl} {
          font-size: 20px;
        }
        @media #{$desktop} {
          font-size: 16px;
        }
        @media #{$undermd} {
          font-size: 30px;
        }
        @media #{$small-phone} {
          font-size: 25px;
        }
      }
      .link-category{
        border-radius: 50%;
        padding: 5px 14px;
        color: $white;
        font-size: 25px;
        background: $blue;
        float: right;
        margin-right: 20px;
        margin-top: -20px;
        display: block;
        position: relative;
        margin-bottom: 20px;
      }

      h4:before{
        content: "•";
        color: $blue;
        display: inline-block;
        width: 0em;
        font-size: 45px;
        line-height: 10px;
        position: relative;
        right: -15px;
        top: 10px;
        float: right;
        @media #{$desktop} {
          right: -5px;
        }
      }

    }
    .box-applicazione:hover{
      background: $lightGrey;
      border-bottom: 8px solid $grey;

      img{
        transition: all 0.6s ease;
        -webkit-transition: all 0.6s ease;
        -o-transition: all 0.6s ease;
        -moz-transition: all 0.6s ease;
      }

      h4:before{
        content: "•";
        color: $grey;
        display: inline-block;
        width: 0em;
        font-size: 45px;
        line-height: 10px;
        position: relative;
        right: -15px;
        top: 10px;
        float: right;
      }

      .link-category{
        border-radius: 50%;
        padding: 5px 14px;
        color: $white;
        font-size: 25px;
        background: $grey;
        float: right;
        margin-right: 20px;
        margin-top: -20px;
        display: block;
        position: relative;
        margin-bottom: 20px;
      }
    }
  }
}

#categoria-applicazioni{
  #descrizione{
    text-align: center;
    background: $lightGrey;
    padding-bottom: 40px;
  }
  .titolo-sezione{
    margin-top: 20px;
  }
}
