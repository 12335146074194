.cta-page, .cta-prodotti{
  background: $blue;

  // @media #{$undermd} {
  //   margin-top: 40px;
  // }

  .icona{
    text-align: center;
    img{
      max-width: 80px;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }
  .img-sx{
    figure{
      width: 100%;
      position: relative;
      max-width: inherit;
      @media #{$under-desktop-xl} {
        height: 300px;
      }
      @media #{$undermd} {
        height: 300px;
      }
      @media #{$undertablet} {
        height: 100%;
      }

      .img-cover {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        width: 100%;
        z-index: -10;
        opacity: 1;
        @media #{$under-desktop-xl} {
          height: 100%;
        }
        @media #{$undermd} {
          height: 100%;
        }
        @media #{$undertablet} {
          height: 500px;
        }
        @media #{$small-phone} {
          height: 100%;
        }
      }


      figcaption{
        position: absolute;
        left: 50%;
        top: 50%;
        @include transform (translate(-50%,-50%));
        @media #{$underdesktop} {
          width: 90%;
        }

        .titolo{
          color: $white;
          font-size: 50px;
          text-align: center;
          position: relative;
          text-transform: none;
          display: inline-block;
          margin-bottom: 20px;
          padding: 0 40px;

          h4{
            font-size: 40px;
            letter-spacing: 1px;
            font-weight: 400;
            @media #{$small-phone} {
              font-size: 25px;
              line-height: 30px;
            }
          }
        }
        .titolo:before{
          content: "•";
          color: $white;
          display: inline-block;
          width: .1em;
          font-size: 40px;
          line-height: 10px;
          position: absolute;
          top: 50%;
          @include transform (translateY(-50%));
          margin-right: 0px;
          left: 20px;
        }
        .titolo:after{
          content: "•";
          color: $white;
          display: inline-block;
          width: .1em;
          font-size: 40px;
          line-height: 10px;
          position: absolute;
          top: 50%;
          @include transform (translateY(-50%));
          margin-left: 0px;
          right: 30px;
        }

        .descrizione{
          color: $white;
          text-align: center;
          p{
            margin-top: 5px;
            line-height: 25px;
            font-weight: 300;
          }
          .cta-button{

          }
        }


      }
    }
  }

}

.cta-prodotti{
  .cta-button{
    padding: 10px 40px;
  }
}
